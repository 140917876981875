
import { alias47916751af154eb5b47cd5de9d34633d } from '@/customFolder/customVueAlias.js';
/**
 * @Description: 菜单
 * @author gan
 * @date: 2020/12/17 11:09
 * @attribute name 名称
 * @attribute path 路径
 * @attribute icon 图标
 * @attribute children 子菜单
 * @attribute menuKey 菜单key(需要唯一，用于获取权限)
 */
import {setConfigCharacters} from "@/utils/common";

export default {
  menu: [
    {
      name: alias47916751af154eb5b47cd5de9d34633d.t('key1005493'),
      icon: '&#xe67b;',
      path: '/statistics',
      menuKey: 'yms_statistics',
    },
    {
      name: alias47916751af154eb5b47cd5de9d34633d.t('key1005814'),
      icon: '&#xe749;',
      type: 'productList',
      children: [
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1002217'),
          path: '/productList',
          menuKey: 'yms_productList',
          children: [
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1002662'),
              path: '/viewSku',
              menuKey: 'yms_viewSku',
              menuHide: true // 菜单不展示
            },
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1006918'),
              path: '/productDetails',
              menuKey: 'yms_productDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1002811'),
          path: '/category',
          menuKey: 'yms_category',
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006919'),
          path: '/sizeTemplateManage',
          menuKey: 'yms_sizeTemplateManage',
          children: [
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1006920'),
              path: '/updateSizeTemplate',
              menuKey: 'yms_updateSizeTemplate',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006921'),
          path: '/approvalList',
          menuKey: 'yms_approvalList',
          children: [
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1006922'),
              path: '/approvalDetails',
              menuKey: 'yms_approvalDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1002665'),
              path: '/findSimilarProducts',
              menuKey: 'yms_findSimilarProducts',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1002228'),
          path: '/itemAttributes',
          menuKey: 'yms_itemAttributes'
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1002657'),
          path: '/productLabel',
          menuKey: 'yms_productLabel'
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006923'),
          path: '/productSettings',
          menuKey: 'yms_productSettings',
          jurisdictionHide: true, // 不需要权限判断
          hideMenu: true, // 在权限设置中不展示此菜单
        },
      ]
    },
    {
      name: alias47916751af154eb5b47cd5de9d34633d.t('key1001148'),
      icon: '&#xe665;',
      type: 'orderList',
      children: [
        {
          name: `${setConfigCharacters}${alias47916751af154eb5b47cd5de9d34633d.t('key1001448')}`,
          path: '/orderList',
          menuKey: 'yms_orderList',
          children: [
            {
              name: `${setConfigCharacters}${alias47916751af154eb5b47cd5de9d34633d.t('key1005083')}`,
              path: '/orderListDetails',
              menuKey: 'yms_orderListDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1006924'),
              path: '/supplierOrderDetails',
              menuKey: 'yms_supplierOrderDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006925'),
          path: '/distributorStockList',
          menuKey: 'yms_distributorStockList'
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006926'),
          path: '/supplierStockList',
          menuKey: 'yms_supplierStockList',
          children: [
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1006927'),
              path: '/supplierStockOrderDetails',
              menuKey: 'yms_supplierStockOrderDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1002227'),
          path: '/supplierOrders',
          menuKey: 'yms_supplierOrders'
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006928'),
          path: '/orderPaymentSettings',
          menuKey: 'yms_orderPaymentSettings',
          jurisdictionHide: true, // 不需要权限判断
          hideMenu: true, // 在权限设置中不展示此菜单
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006929'),
          path: '/orderCollectionAccount',
          menuKey: 'yms_orderCollectionAccount'
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1003778'),
          path: '/orderPaymentReconciliation',
          menuKey: 'yms_orderPaymentReconciliation'
        },
      ]
    },
    {
      name: alias47916751af154eb5b47cd5de9d34633d.t('key1000018'),
      icon: '&#xe679;',
      type: 'wms',
      children: [
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1002219'),
          path: '/wms/inventoryLevels',
          menuKey: 'yms_wms_inventoryLevels'
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006930'),
          children: [
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1006931'),
              path: '/billLadingManage',
              menuKey: 'yms_billLadingManage',
              children: [
                {
                  name: alias47916751af154eb5b47cd5de9d34633d.t('key1006932'),
                  path: '/billLadingManageDetails',
                  menuKey: 'yms_billLadingManageDetails',
                  menuHide: true // 菜单不展示
                }
              ]
            },
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1001956'),
              path: '/billWarehousing',
              menuKey: 'yms_billWarehousing'
            },
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1001957'),
              path: '/commoditySorting',
              menuKey: 'yms_commoditySorting'
            },
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1002218'),
              path: '/commoditySticker',
              menuKey: 'yms_commoditySticker'
            },
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1001960'),
              path: '/commodityShelves',
              menuKey: 'yms_commodityShelves'
            }
          ]
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006007'),
          children: [
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1002562'),
              path: '/assembleGeneratePickingList',
              menuKey: 'yms_assembleGeneratePickingList'
            },
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1006933'),
              path: '/assembleGeneratePickingDetails',
              menuKey: 'yms_assembleGeneratePickingDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1006924'),
              path: '/assembleSupplierOrderDetails',
              menuKey: 'yms_assembleSupplierOrderDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1006934'),
              path: '/assemblePickingList',
              menuKey: 'yms_assemblePickingList'
            },
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1002527'),
              path: '/assemblePickingListDetails',
              menuKey: 'yms_assemblePickingListDetails',
              menuHide: true // 菜单不展示
            },
          ]
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1001344'),
          children: [
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1002562'),
              path: '/wms/generateOrderList',
              menuKey: 'yms_wms_generateOrderList'
            },
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1006933'),
              path: '/generateOrderDetails',
              menuKey: 'yms_generateOrderDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1006924'),
              path: '/generateSupplierOrderDetails',
              menuKey: 'yms_generateSupplierOrderDetails',
              menuHide: true // 菜单不展示
            },
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1006934'),
              path: '/wms/pickList',
              menuKey: 'yms_wms_pickList'
            },
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1002527'),
              path: '/wms/pickListDetails',
              menuKey: 'yms_wms_pickListDetails',
              menuHide: true // 菜单不展示
            },
          ]
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006107'),
          children: [
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1006935'),
              path: '/wms/sortOrderSetting',
              menuKey: 'yms_wms_sortOrderSetting'
            },
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1001961'),
              path: '/wms/sorting',
              menuKey: 'yms_wms_sorting'
            },
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1001963'),
              path: '/wms/packWorking',
              menuKey: 'yms_wms_packWorking'
            },
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1001964'),
              path: '/sortingOut',
              menuKey: 'yms_sortingOut'
            },
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1006936'),
              path: '/handoverSheetManage',
              menuKey: 'yms_handoverSheetManage',
              children: [
                {
                  name: alias47916751af154eb5b47cd5de9d34633d.t('key1006937'),
                  path: '/handoverSheetManageDetails',
                  menuKey: 'yms_handoverSheetManageDetails',
                  menuHide: true // 菜单不展示
                }
              ]
            }
          ]
        }
      ]
    },
    {
      name: alias47916751af154eb5b47cd5de9d34633d.t('key1006938'),
      icon: '&#xe668;',
      type: 'supplierList',
      children: [
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006939'),
          path: '/supplierList',
          menuKey: 'yms_supplierList'
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006940'),
          path: '/supplierAuditList',
          menuKey: 'yms_supplierAuditList'
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006941'),
          path: '/supplierLevel',
          menuKey: 'yms_supplierLevel'
        }
      ]
    },
    {
      name: alias47916751af154eb5b47cd5de9d34633d.t('key1006942'),
      icon: '&#xe699;',
      type: 'distributorList',
      children: [
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006943'),
          path: '/customerList',
          menuKey: 'yms_customerList',
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1002465'),
          path: '/customerLabel',
          menuKey: 'yms_customerLabel',
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1002464'),
          path: '/customerGroups',
          menuKey: 'yms_customerGroups',
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1002466'),
          path: '/customerGrade',
          menuKey: 'yms_customerGrade'
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006944'),
          path: '/tuokeLink',
          menuKey: 'yms_tuokeLink'
        }
      ]
    },
    {
      name: alias47916751af154eb5b47cd5de9d34633d.t('key1001149'),
      icon: '&#xe668;',
      type: 'supplierBillManage',
      children: [
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006945'),
          path: '/supplierBillManage',
          menuKey: 'yms_supplierBillManage',
          children: [
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1006946'),
              path: '/supplierBillDetails',
              menuKey: 'yms_supplierBillDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1002229'),
          path: '/customerBill',
          menuKey: 'yms_customerBill',
          children: [
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1006947'),
              path: '/customerBillDetails',
              menuKey: 'yms_customerBillDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1002230'),
          path: '/logisticsBill',
          menuKey: 'yms_logisticsBill',
          children: [
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1006948'),
              path: '/logisticsBillDetails',
              menuKey: 'yms_logisticsBillDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1002231'),
          path: '/warehouseBill',
          menuKey: 'yms_warehouseBill',
          children: [
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1006949'),
              path: '/warehouseBillDetails',
              menuKey: 'yms_warehouseBillDetails',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1002875'),
          path: '/financialJournal',
          menuKey: 'yms_financialJournal'
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1002224'),
          path: '/rechargeRecord',
          menuKey: 'yms_rechargeRecord'
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006950'),
          path: '/passwordManage',
          menuKey: 'yms_passwordManage'
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1002223'),
          path: '/subsidyReview',
          menuKey: 'yms_subsidyReview'
        }
      ]
    },
    {
      name: alias47916751af154eb5b47cd5de9d34633d.t('key1006951'),
      icon: '&#xe68e;',
      type: 'logisticsManage',
      children: [
        {
          name: `${setConfigCharacters}${alias47916751af154eb5b47cd5de9d34633d.t('key1003045')}`,
          path: '/ymsLogistics',
          menuKey: 'yms_ymsLogistics'
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006952'),
          path: '/basicLogistics',
          menuKey: 'yms_basicLogistics'
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006953'),
          path: '/smartLogisticsRule',
          menuKey: 'yms_smartLogisticsRule'
        },
      ]
    },
    {
      name: alias47916751af154eb5b47cd5de9d34633d.t('key1001164'),
      icon: '&#xe68f;',
      type: 'warehouseManage',
      children: [
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006954'),
          path: '/warehouseManage',
          menuKey: 'yms_warehouseManage'
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006955'),
          path: '/reservoirManage',
          menuKey: 'yms_reservoirManage'
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006956'),
          path: '/locationManage',
          menuKey: 'yms_locationManage'
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006957'),
          path: '/messageNotification',
          menuKey: 'yms_messageNotification'
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1001690'),
          path: '/exchangeRateConfig',
          menuKey: 'yms_exchangeRateConfig'
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006958'),
          path: '/containerManage',
          menuKey: 'yms_containerManage'
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006959'),
          path: '/containerSettings',
          menuKey: 'yms_containerSettings'
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006960'),
          path: '/addressLibraryManage',
          menuKey: 'yms_addressLibraryManage'
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006961'),
          path: '/processConfiguration',
          menuKey: 'yms_processConfiguration'
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006962'),
          path: '/iossTaxRateSetting',
          menuKey: 'yms_iossTaxRateSetting'
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006963'),
          path: '/mallConfiguration',
          menuKey: 'yms_mallConfiguration',
          jurisdictionHide: true, // 不需要权限判断
          hideMenu: true, // 在权限设置中不展示此菜单
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1001000'),
          children: [
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1006964'),
              path: '/declarationRules',
              menuKey: 'yms_declarationRules'
            }
          ]
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1001148'),
          children: [
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1006965'),
              path: '/automaticDistributionRules',
              menuKey: 'yms_automaticDistributionRules'
            }
          ]
        }
      ]
    },
    {
      name: alias47916751af154eb5b47cd5de9d34633d.t('key1006966'),
      icon: '&#xe68b;',
      type: 'helpCenter',
      children: [
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1000036'),
          path: '/helpCenter',
          menuKey: 'yms_helpCenter',
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006967'),
          path: '/questionnaireInvestigation',
          menuKey: 'yms_questionnaireInvestigation',
          children: [
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1002164'),
              path: '/questionnaireStatistics',
              menuKey: 'yms_questionnaireStatistics',
              menuHide: true // 菜单不展示
            },
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1002258'),
              path: '/addQuestionnaire',
              menuKey: 'yms_addQuestionnaire',
              menuHide: true // 菜单不展示
            },
            {
              name: alias47916751af154eb5b47cd5de9d34633d.t('key1006968'),
              path: '/editQuestionnaire',
              menuKey: 'yms_editQuestionnaire',
              menuHide: true // 菜单不展示
            }
          ]
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006969'),
          path: '/operatingInstructions',
          menuKey: 'yms_operatingInstructions',
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1000885'),
          id: 'toSystemAnnouncement',
          jurisdictionHide: true, // 不需要权限判断
          hideMenu: true, // 在权限设置中不展示此菜单
          externalLink: true, // 是否跳转到外部系统的标识
          menuKey: 'yms_systemAnnouncement',
        },
      ]
    },
    {
      name: alias47916751af154eb5b47cd5de9d34633d.t('key1006970'),
      icon: '&#xe691;',
      type: 'dataCenter',
      children: [
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1002221'),
          path: '/deliveryDetails',
          menuKey: 'yms_deliveryDetails',
        }
      ]
    },
    {
      name: alias47916751af154eb5b47cd5de9d34633d.t('key1006971'),
      icon: '&#xe66a;',
      type: 'activityManage',
      children: [
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006972'),
          path: '/siteManage',
          menuKey: 'yms_siteManage',
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006973'),
          path: '/activityManage',
          menuKey: 'yms_activityManage',
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006974'),
          path: '/editActivity',
          menuKey: 'yms_editActivity',
          menuHide: true // 菜单不展示
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006975'),
          path: '/advertisingManage',
          menuKey: 'yms_advertisingManage',
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1004137'),
          path: '/rotationChart',
          menuKey: 'yms_rotationChart',
          menuHide: true // 菜单不展示
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1004104'),
          path: '/vajraDistrict',
          menuKey: 'yms_vajraDistrict',
          menuHide: true // 菜单不展示
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1004138'),
          path: '/topAdvertisingSpace',
          menuKey: 'yms_topAdvertisingSpace',
          menuHide: true // 菜单不展示
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1004139'),
          path: '/sideAdvertisingSpace',
          menuKey: 'yms_sideAdvertisingSpace',
          menuHide: true // 菜单不展示
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1004140'),
          path: '/infoAdvertisingSpace',
          menuKey: 'yms_infoAdvertisingSpace',
          menuHide: true // 菜单不展示
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1003047'),
          path: '/subsidyRules',
          menuKey: 'yms_subsidyRules',
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006976'),
          path: '/distributorFeeAllocation',
          menuKey: 'yms_distributorFeeAllocation',
        },
      ]
    },
    {
      name: alias47916751af154eb5b47cd5de9d34633d.t('key1000290'),
      path: '/messageCenter',
      icon: '&#xe8be;',
      menuKey: 'yms_messageCenter',
      jurisdictionHide: true, // 不需要权限判断
      type: 'messageCenter',
      children: [
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006977'),
          path: '/messageMailboxes',
          menuKey: 'yms_messageMailboxes',
          menuHide: true // 菜单不展示
        },
      ]
    },
    {
      name: alias47916751af154eb5b47cd5de9d34633d.t('key1006978'),
      icon: '&#xe693;',
      path: '/employeeList',
      menuKey: 'yms_employeeList',
      type: 'employeeList',
    },
    {
      name: alias47916751af154eb5b47cd5de9d34633d.t('key1006979'),
      icon: '&#xe664;',
      type: 'importTask',
      children: [
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006980'),
          path: '/importTask',
          menuKey: 'yms_importTask'
        },
        {
          name: alias47916751af154eb5b47cd5de9d34633d.t('key1006981'),
          path: '/exportTask',
          menuKey: 'yms_exportTask'
        }
      ]
    }
  ]
};
